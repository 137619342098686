import React from 'react';
import { Box, Flex, Text, Button } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';
import Fade from 'react-reveal/Fade';
import { scrollToEl } from '../../../helpers/functions';

const KeyTakeaways = ({ compensationImages }) => (
  <Box pt='35px'>
    <Text
      color='nextonOrange'
      fontSize={['38px', '60px']}
      fontWeight='700'
      letterSpacing='-1px'
      pt={['30px', '45px']}
      as='h2'>
      Key takeaways from our latest
    </Text>
    <Text
      color='nextonOrange'
      fontSize={['38px', '60px']}
      fontWeight='400'
      letterSpacing='-1px'
      pb={['35px', '75px']}
      as='h2'>
      tech salaries report:
    </Text>

    <Flex flexWrap='wrap'>
      <Box width={[1, 1, 3 / 5]} pr={['0px', '0px', '40px']} pb={['0px', '35px']}>
        <Flex pb={['36px', '48px']}>
          <Text color='grey800' fontSize={['20px', '28px']} lineHeight='1.4' mr='18px'>
            &#8226;
          </Text>
          <Text color='grey800' fontSize={['20px', '28px']} lineHeight='1.4' letterSpacing='-0.5px'>
            The current and expected salaries for senior tech roles such as Front-End, Back-End and Full-Stack
            Developers in Latin America.
          </Text>
        </Flex>
        <Flex pb={['36px', '48px']}>
          <Text color='grey800' fontSize={['20px', '28px']} lineHeight='1.4' mr='18px'>
            &#8226;
          </Text>
          <Text color='grey800' fontSize={['20px', '28px']} lineHeight='1.4' letterSpacing='-0.5px'>
            The annual salary for roles such as Data Engineers and SRE / DevOps saw a slight drop.
          </Text>
        </Flex>
        <Flex pb={['36px', '48px']}>
          <Text color='grey800' fontSize={['20px', '28px']} lineHeight='1.4' mr='18px'>
            &#8226;
          </Text>
          <Text color='grey800' fontSize={['20px', '28px']} lineHeight='1.4' letterSpacing='-0.5px'>
            Tech Lead's annual compensation decreased sharply, from USD 100.800 to USD 72.000.
          </Text>
        </Flex>
        <Flex pb={['36px', '48px']}>
          <Text color='grey800' fontSize={['20px', '28px']} lineHeight='1.4' mr='18px'>
            &#8226;
          </Text>
          <Text color='grey800' fontSize={['20px', '28px']} lineHeight='1.4' letterSpacing='-0.5px'>
            What you can expect when recruiting in the Latin American region —given the current economic climate in the
            U.S.
          </Text>
        </Flex>
        <Flex pb={['36px', '48px']}>
          <Text color='grey800' fontSize={['20px', '28px']} lineHeight='1.4' mr='18px'>
            &#8226;
          </Text>
          <Text color='grey800' fontSize={['20px', '28px']} lineHeight='1.4' letterSpacing='-0.5px'>
            How to optimize your recruiting and hiring strategies to attract and retain the strongest engineering talent
            in the region.
          </Text>
        </Flex>
        <Box mt='32px' mb='24px' textAlign={['center', 'left']}>
          <Button variant='newMainOrange' onClick={() => scrollToEl('#compensation-trends-form')}>
            DISCOVER THE SALARIES
          </Button>
        </Box>
      </Box>
      <Box width={[1, 1, 2 / 5]} style={{ position: 'relative' }}>
        <Flex id='nexton-book-container'>
          <Box id='nexton-book-desktop'>
            <Fade right delay={800}>
              <GatsbyImage image={compensationImages.nextonBook} alt='Nexton Book' />
            </Fade>
          </Box>
          {/* On Mobile animate from the left to avoid Content Wider than Screen issue */}
          <Box id='nexton-book-mobile'>
            <Fade left delay={800}>
              <GatsbyImage image={compensationImages.nextonBook} alt='Nexton Book' />
            </Fade>
          </Box>
        </Flex>
      </Box>
    </Flex>
  </Box>
);

export default KeyTakeaways;
