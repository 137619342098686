import { siteUrl } from './constants';

export const shareToLinkedIn = uri => {
  const liSharer = `https://www.linkedin.com/shareArticle?mini=true&url=`;
  const fullUrl = `${liSharer}${siteUrl}/${uri}`;
  window.open(
    fullUrl,
    'LinkedIn',
    'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,width=580,height=620,resizable=1',
  );
};

export const shareToTwitter = uri => {
  const twSharer = `https://twitter.com/intent/tweet?url=`;
  const fullUrl = `${twSharer}${siteUrl}/${uri}`;
  window.open(
    fullUrl,
    'Twitter',
    'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,width=550,height=470,resizable=1',
  );
};

export const shareToFacebook = uri => {
  const fbSharer = `https://www.facebook.com/sharer/sharer.php?u=`;
  const fullUrl = `${fbSharer}${siteUrl}/${uri}`;
  window.open(
    fullUrl,
    'Facebook',
    'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,width=600,height=400,resizable=1',
  );
};
