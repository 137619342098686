import React from 'react';
import { Box, Flex, Text, Link } from 'rebass';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitterSquare, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

import Jump from 'react-reveal/Jump';
import { shareToLinkedIn, shareToTwitter, shareToFacebook } from '../../../helpers/shareUrls';

const IconLink = styled(Link)`
  font-size: 48px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.nextonOrange};
  cursor: pointer;
  transition: filter 0.2s ease-in-out;

  margin: 0px 12px;

  &:hover {
    filter: brightness(120%);
  }
`;

const ShareReport = ({ uri }) => (
  <Box className='body-layout' pt={['35px', '35px']} pb={['75px', '35px']}>
    <Flex alignItems='center' justifyContent='center' flexWrap='wrap' textAlign='center'>
      <Text
        color='nextonOrange'
        fontSize={['40px', '60px']}
        fontWeight='500'
        letterSpacing='-1px'
        my='36px'
        mr={['0px', '55px']}
        as='h3'>
        Share the report!
      </Text>

      <Flex>
        <IconLink onClick={() => shareToLinkedIn(uri)}>
          <Jump delay={1000}>
            <FontAwesomeIcon icon={faLinkedin} />
          </Jump>
        </IconLink>

        <IconLink onClick={() => shareToTwitter(uri)}>
          <Jump delay={1300}>
            <FontAwesomeIcon icon={faTwitterSquare} />
          </Jump>
        </IconLink>

        <IconLink onClick={() => shareToFacebook(uri)}>
          <Jump delay={1600}>
            <FontAwesomeIcon icon={faFacebookSquare} />
          </Jump>
        </IconLink>
      </Flex>
    </Flex>
  </Box>
);

export default ShareReport;
