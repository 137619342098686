import { useState, useEffect } from 'react';

// Accounts for Gatsby SSR production build not having document or window defined
export const useHasMounted = () => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => setHasMounted(true), []);
  return hasMounted;
};

// Checks if the Hubspot forms script has loaded, so we can execute it's functions (e.g. create a form)
export const useHSFormsScriptLoaded = () => {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    const HSFormsScript = document.getElementById('hubspot-forms-script');
    if (HSFormsScript) HSFormsScript.addEventListener('load', () => setHasLoaded(true));
    // In case it already loaded before adding the listener, we can check with:
    if (window && window.hbspt) setHasLoaded(true);
  }, []);

  return hasLoaded;
};
