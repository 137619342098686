import React, { useEffect } from 'react';
import { Box, Flex, Text, Image } from 'rebass';
import { Link as GatsbyLink } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useMediaQuery } from 'react-responsive';
import { useHSFormsScriptLoaded } from '../../../helpers/hooks';

import WhiteRoundCard2 from '../../../components/client/WhiteRoundCard2';
import NextonLogo from '../../../../assets/images/logo/nexton-logo-white.svg';

const CompensationReportHero = ({ compensationImages }) => {
  const HSFormsScriptLoaded = useHSFormsScriptLoaded();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 960px)',
  });

  useEffect(() => {
    if (HSFormsScriptLoaded) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '14573977',
        formId: '374e7fae-07b7-4320-93b9-4f4dc18f01b5',
        target: '#compensation-trends-form',
      });
    }
  }, [HSFormsScriptLoaded]);

  return (
    <Box className='body-layout' mt='100px'>
      <Flex flexWrap='wrap' pt={['0px', '40px']}>
        <Box height='40px' width='100%' />
        <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
          <GatsbyLink to='/'>
            <Image src={NextonLogo} alt='Nexton Logo' className='bright-hover' mb='12px' />
          </GatsbyLink>
          <Text
            color='white'
            fontSize={['40px', '42px']}
            fontWeight='500'
            letterSpacing='-1px'
            pt={['30px', '45px']}
            pb={['24px', '24px']}
            as='h1'>
            2022 Tech Salaries and Benefits Trends: Q3 Report
          </Text>
          <Text
            color='white'
            fontSize={['24px', '28px']}
            lineHeight='1.4'
            letterSpacing='-0.5px'
            pb='50px'
            as='h2'
            fontWeight='400'>
            Boost your recruiting and retention strategies with our insights.
          </Text>
          {isDesktopOrLaptop && <GatsbyImage image={compensationImages.compensationHero} alt='Compensation Hero' />}
        </Box>
        <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
          <Box mt={['0px', '20px']} mb={['100px', '100px', '0px']}>
            <WhiteRoundCard2 padding='40px 35px' maxWidth='560px'>
              <Box>
                <Text
                  color='nextonBlue'
                  fontSize={['22px', '28px']}
                  fontWeight={500}
                  textAlign='center'
                  mt='10px'
                  mb='40px'>
                  To view our data-driven insights, fill out the form below
                </Text>
                <div id='compensation-trends-form' style={{ minHeight: '250px' }} />
              </Box>
            </WhiteRoundCard2>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default CompensationReportHero;
