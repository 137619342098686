import React from 'react';
import { Box } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';

import CompensationReportHero from '../sections/Landings/compensation/CompensationReportHero';
import ReportHelpYou from '../sections/Landings/compensation/ReportHelpYou';
import KeyTakeaways from '../sections/Landings/compensation/KeyTakeaways';
import ShareReport from '../sections/Landings/compensation/ShareReport';

const camelCase = require('camelcase');

const CompensationTrends = () => {
  const { compensation } = useStaticQuery(graphql`
    {
      compensation: allFile(filter: { relativeDirectory: { eq: "images/landings/compensation" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED, formats: [PNG])
          }
          name
        }
      }
    }
  `);
  const compensationImages = {};
  compensation.nodes.forEach(img => {
    compensationImages[camelCase(img.name)] = { ...img.childImageSharp.gatsbyImageData };
  });

  return (
    <>
      <MetaData
        title='2022 Tech Salaries and Benefits Trends: Q3 Report'
        description='Boost your recruiting and retention strategies with our insights'
      />
      <Layout selected='ad-landing-page' header={false}>
        <Box className='full-width hero' bg='blue400'>
          <CompensationReportHero compensationImages={compensationImages} />
        </Box>
        <ReportHelpYou compensationImages={compensationImages} />
        <KeyTakeaways compensationImages={compensationImages} />
        <Box className='full-width' bg='nextonLightBlue'>
          <ShareReport uri='tech-salaries-and-benefits-trends-report' />
        </Box>
      </Layout>
    </>
  );
};

export default CompensationTrends;
