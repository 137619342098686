import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';

import Rotate from 'react-reveal/Rotate';
import WhiteRoundCard2 from '../../../components/client/WhiteRoundCard2';
import ReportHelpYouBubbles from '../../../components/landings/compensation/ReportHelpYouBubbles';

const ReportHelpYou = ({ compensationImages }) => (
  <Box pt={['0px', '75px']} pb={['45px', '75px']} style={{ position: 'relative' }}>
    <Text
      fontSize={['38px', '60px']}
      fontWeight='700'
      letterSpacing='-1px'
      color='nextonOrange'
      textAlign='right'
      as='h2'>
      Nexton's IT salaries & benefits
    </Text>
    <Text
      fontSize={['38px', '60px']}
      fontWeight='400'
      letterSpacing='-1px'
      color='nextonOrange'
      textAlign='right'
      mb='64px'
      as='h2'>
      report will help you to:
    </Text>

    <Flex flexWrap='wrap' justifyContent='center'>
      <Box width={[1, 1 / 2, 1 / 3, 1 / 4]} px={['0px', '10px']} py='15px'>
        <Rotate top left delay={1000}>
          <WhiteRoundCard2 padding='24px 24px' maxWidth='560px'>
            <Box textAlign='center' minHeight='222px'>
              <GatsbyImage image={compensationImages.learn} alt='Learn' />
              <Text fontSize={['24px', '28px']} color='nextonBlack' fontWeight='500' my='12px'>
                Learn
              </Text>
              <Text color='grey700' fontSize='18px' letterSpacing='-0.5px' lineHeight='1.4'>
                the 2022 IT salary evolution quarter over quarter for senior technology roles.
              </Text>
            </Box>
          </WhiteRoundCard2>
        </Rotate>
      </Box>
      <Box width={[1, 1 / 2, 1 / 3, 1 / 4]} px={['0px', '10px']} py='15px'>
        <Rotate top left delay={1600}>
          <WhiteRoundCard2 padding='24px 24px' maxWidth='560px'>
            <Box textAlign='center' minHeight='222px'>
              <GatsbyImage image={compensationImages.discover} alt='Discover' />
              <Text fontSize={['24px', '28px']} color='nextonBlack' fontWeight='500' my='12px'>
                Discover
              </Text>
              <Text color='grey700' fontSize='18px' letterSpacing='-0.5px' lineHeight='1.4'>
                the top benefits that are being offered by U.S. companies hiring remotely in Latin America.
              </Text>
            </Box>
          </WhiteRoundCard2>
        </Rotate>
      </Box>
      <Box width={[1, 1 / 2, 1 / 3, 1 / 4]} px={['0px', '10px']} py='15px'>
        <Rotate top left delay={2200}>
          <WhiteRoundCard2 padding='24px 24px' maxWidth='560px'>
            <Box textAlign='center' minHeight='222px'>
              <GatsbyImage image={compensationImages.unlock} alt='Unlock' style={{ margin: '4px 0px' }} />
              <Text fontSize={['24px', '28px']} color='nextonBlack' fontWeight='500' my='12px'>
                Unlock
              </Text>
              <Text color='grey700' fontSize='18px' letterSpacing='-0.5px' lineHeight='1.4'>
                value from the data Nexton collected through 1037 senior developers.
              </Text>
            </Box>
          </WhiteRoundCard2>
        </Rotate>
      </Box>
      <Box width={[1, 1 / 2, 1 / 3, 1 / 4]} px={['0px', '10px']} py='15px'>
        <Rotate top left delay={2800}>
          <WhiteRoundCard2 padding='24px 24px' maxWidth='560px'>
            <Box textAlign='center' minHeight='222px'>
              <GatsbyImage image={compensationImages.optimize} alt='Optimize' />
              <Text fontSize={['24px', '28px']} color='nextonBlack' fontWeight='500' my='12px'>
                Optimize
              </Text>
              <Text color='grey700' fontSize='18px' letterSpacing='-0.5px' lineHeight='1.4'>
                your recruiting efforts by understanding what to expect from remote recruiting in Q4'22.
              </Text>
            </Box>
          </WhiteRoundCard2>
        </Rotate>
      </Box>
    </Flex>
    <ReportHelpYouBubbles />
  </Box>
);

export default ReportHelpYou;
